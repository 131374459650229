import Logo from './images/logo.png';
import Loginimg from './images/computer.png';
export const SERVER_URL = "https://www.trovebooks.in/";
export const ASSET_URL = "https://storage.googleapis.com/nova-openfilesstore/trovebooks/unsecured/";
export const REST_API_URL = SERVER_URL+"api/v1";
export const APP_NAME="Trove Books";
export const LOGO=Logo;
export const LOGINIMG=Loginimg;
export const FbLoginID= 1007931699847440;
export const AppleClientId="co.novareader.researchpad";
export const GmailClientId="1062603662906-hbk987al4lds9mnsvjbhf4itvic7ojle.apps.googleusercontent.com";
export const OrcidClientId="https://orcid.org/oauth/authorize?client_id=APP-NE6GOPUF09930F9B&response_type=code&scope=/authenticate&redirect_uri=https://mobile.trovebooks.in/login";
export const URL="https://mobile.trovebooks.in/";
export const AppVersion="v1.0.1.18";
export const RedirectUri="https://www.trovebooks.in/api/applelogin";
export const Cover="https://storage.googleapis.com/nova-openfilesstore/trovebooks/unsecured/";
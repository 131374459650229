import { useState } from "react";
import { Col, message } from 'antd';
import { bookcover, search } from '../../api/api'
import { useHistory } from 'react-router-dom'
import { LoadingOutlined, CloseOutlined} from '@ant-design/icons';
import { updateSeachbooks } from '../../redux/actions/dashboardActions';
import { useSelector, useDispatch } from 'react-redux';
import { FiSearch} from "react-icons/fi";
import PageHader from "../../component/pageheader/pageHeader";
import Space from '../../component/dashboard/space';

import './styles.css';
import moment from "moment";

const Searchscreen = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isloading, setisloading] = useState(false)
    const [ismoreloading, setismoreloading] = useState(false)
    const [ismoredata, setismoredata] = useState(true)
    const searchbooksvalues = useSelector((state) => state.App.searchbooksvalues);
    const [text, settext] = useState(searchbooksvalues.searchtext)
    const [visible, setvisible] = useState(false)
    const lpp_userCredentials = JSON.parse(localStorage.getItem('lpp_userCredentials'));
    const showuser = useSelector((state) => state.App.showlogin);

    const handleVisibleChange = visible => {
        setvisible(visible)
      };
    const getBooksList = async () => {
        message.destroy()
        let url = search;
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(url+"q="+searchbooksvalues.searchtext+"&sort=recentold", requestOptions,)
            .then(response => response.json())
            .then(result => {
                if(result.pagination.count==0){
                    setismoredata(false)
                    message.warning('No Data Found ');

                }else{
                    dispatch(updateSeachbooks(searchbooksvalues.books.concat(result.data), searchbooksvalues.pagination + 20, text))

                }
                setisloading(false)
                setismoreloading(false)

            })
            .catch(error => {
                setisloading(false)
                setismoreloading(false)
            });

    }
    const login = () => {
        history.replace('./login');
    }
     

    const onSearch = () => {
        message.destroy()
        console.log(text.length)
        if (text.length==0) {
            message.warning('Please fill the search box');

        }else{
            dispatch(updateSeachbooks([], 0, text))
            setismoredata(true)
            setisloading(true)
            getBooksList()
        }
    }

    const onClear = () => {
        settext("")
        dispatch(updateSeachbooks([], 0, ""))

    }

    const loademore = () => {
        setismoreloading(true)
        getBooksList()
    }

    const updatetext = event => {
        settext(event.target.value)
    }
    
    const handleAnswerChange=(event)=>{
                message.destroy()

        console.log(event.key)
        if (event.key=="Enter") {
            if (text.length==0) {
                message.warning('Please fill the search box');
    
            }else{
                dispatch(updateSeachbooks([], 0, text))
                setismoredata(true)
                setisloading(true)
                getBooksList()
            }

        } 
		
	
}
const trancateTitle= (title)=> {
    try{
        var length = 20;
        if (title.length > length) {
           title = title.substring(0, length)+'...';
        }
        return title;
    }catch{
        return title;

    }
    
}
    const goToDeacriton = (item) => {   
        history.push({pathname:'/book/isbn/'+ item.identifier,state:{contentid:item.contentId,fuzzyid:item.fuzzyId}})
    }

    const BookList = (books) => {
        return (
            <div className="book-list">
                {books.map((book, index) => (
                    <div key={index} className="book-item" onClick={() => { goToDeacriton(book) }}>
                        <img src={bookcover + book.fuzzyId + "/" + book.cover} alt={book.title} className="book-cover" />
                        <div className="book-details">
                            <h3 className="title">{book.fullName}</h3>
                            <h4>{book.subTitle}</h4>
                            <p>
                                {"authors" in book &&  <strong className='author'>{book.authors.map(item => item.givenName + " " + item.surName).toString().replaceAll(",", ", ")}</strong>}
                            </p>
                            <p>Publication Date: {moment(book.pubDate).format("MMMM DD, YYYY")}</p>
                            <p>Pages: {book.pages}</p>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="continer">
                                   <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>

                                      <PageHader titel={"Search"} hideicon={true}/>    
                                   <div className="searchinput-s">
                    <div className='searchbox'>
                    <FiSearch onClick={onSearch} className='searchicon-s' />                   
                   {isloading && <LoadingOutlined className='loadingicon-s'/>}
                        <input autoFocus={true} onKeyPress={handleAnswerChange}   type="search"   className="searchboxfiled-s" placeholder="What are you looking for?" name="search"  value={text} onChange={updatetext}   />
                         </div>
                </div>
            {searchbooksvalues.books.length > 0 ? 
           <div className="cards-container listcontiner">
             <div className='list-result'>
                  <p className='resulttext'>Results({searchbooksvalues.books.length})</p>
                  <p className='cleartext' onClick={()=>{onClear()}}><span style={{marginRight:'2px'}}>Clear</span><CloseOutlined/></p>

                  </div>
                  {BookList(searchbooksvalues?.books)}
           <Space hig="10px" color="#ffffff" />
 
         </div>
            :
                <div className="nodata">
                    Search by Title,Author,ISBN,Series,Collection,Publisher or subject
                </div>}
                </Col>
        </div>
    );
}


export default Searchscreen;
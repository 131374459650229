import React, { useState } from 'react';
import './styles.css';
import {logo} from '../../api/api'
import { Popover,Input, message } from 'antd';
import User from './userCard';
import { UserOutlined,SearchOutlined, LogoutOutlined, LoginOutlined} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setshowlogin } from '../../redux/actions/dashboardActions';
import { FaUserAlt} from "react-icons/fa";
import { FiSearch} from "react-icons/fi";

const Header = (props) => {
  const dispatch = useDispatch();
  const showuser = useSelector((state) => state.App.showlogin);
  const lpp_userCredentials = JSON.parse(localStorage.getItem('lpp_userCredentials'));

  const history = useHistory();
  
  const handleVisibleChange = () => {
    dispatch(setshowlogin(!showuser))  
  };

  const login = () => {
    history.replace('/login');
}

  return (
    <div className="navheader">
      <div className="notification_box">
        <div className="navlogo">
         <img src={logo} alt="" style={{  height: '100%' }} />
        </div>
      </div>
      <div className="menucontiner">
      <div className="profile">
    {!props.org &&<div className="searchinput" onClick={() => history.push('/search') }>
                    <div className='searchbox'>
                        {/* <input   type="search"   className="searchboxfiled" placeholder="search..." name="search"  /> */}
                        <FiSearch    className='searchicon' />
                    </div>
                </div>}
          {/* <SearchOutlined onClick={() => history.push('/search')} className="menuicon"/> */}
          </div>
        {!props.org && <>
    {lpp_userCredentials?  <Popover open={showuser}
          trigger="click"
          onOpenChange={handleVisibleChange} content={<User hidepop={()=>handleVisibleChange()} />} >
          <div className="profile">
          <FaUserAlt className="menuicon"/>
          </div>
          </Popover>:  <LoginOutlined onClick={login} style={{ color: "#CD4C1F", }} className="menuicon"/>}
        </>}
       
      </div>
    </div>
  )
}

export default Header;
import Logo from './images/logo.png';
import Loginimg from './images/computer.png';
export const SERVER_URL = "https://content.novareader.co/";
export const APP_NAME="Trove Books";
export const LOGO=Logo;
export const LOGINIMG=Loginimg;
export const FbLoginID=388256706125525;
export const AppleClientId="co.novareader.researchpad"
export const GmailClientId="458582913994-ufc1of4nlhc3vmc9h89rdhupeon86bss.apps.googleusercontent.com"
export const OrcidClientId="https://orcid.org/oauth/authorize?client_id=APP-NE6GOPUF09930F9B&response_type=code&scope=/authenticate&redirect_uri=https://mobile.novareader.co/login"
export const URL="https://novamobiletest.web.app/"
export const AppVersion="0.0.5"







import React, { useState, useEffect } from 'react';
import { TabBar } from 'antd-mobile';
import Library from '../library/library';
import 'antd-mobile/dist/antd-mobile.css';
import { MenuUnfoldOutlined, MenuFoldOutlined, FileOutlined, FileDoneOutlined } from '@ant-design/icons';
import{useHistory}from 'react-router-dom'
import Dashboard from '../dashboard/dashboard';

const Home = () => {
  const history=useHistory();
  const [tab, settab] = useState("Home");
  const configFile = process.env.REACT_APP_ENV

  useEffect(() => {
    const lpp_userCredentials = JSON.parse(localStorage.getItem('lpp_userCredentials'));
    if (lpp_userCredentials != null) {
      if (lpp_userCredentials.emailVerified != 1) {
      history.push('./login');
      }
    }
    else {
      history.push('./login');
    }
  }, [])

  return (
    <div >
      <div style={{ position: 'fixed', height: '100%', width: '100%', top: 0, backgroundColor: 'white', }}>
        <TabBar
          unselectedTintColor="#949494"
          tintColor="#CD4C1F"
          barTintColor="white" >
          <TabBar.Item
            title="HOME"
            icon={<MenuFoldOutlined style={{ fontSize: '20px', color: "#949494" }} />}
            selectedIcon={<MenuUnfoldOutlined style={{ fontSize: '20px', color: "#CD4C1F" }} />}
            selected={tab == "Home"}
            onPress={() => settab("Home")}
          >
            <Dashboard />
        </TabBar.Item>
          <TabBar.Item
            icon={<FileOutlined style={{ fontSize: '20px', color: "#949494" }} />}
            selectedIcon={<FileDoneOutlined style={{ fontSize: '20px', color: "#CD4C1F" }} />}
            title="MY LIBRARY"
            selected={tab == "Library"}
            onPress={() => settab("Library")}
          >
            <Library />
          </TabBar.Item>
          <TabBar.Item
            icon={{ uri: 'https://zos.alipayobjects.com/rmsportal/asJMfBrNqpMMlVpeInPQ.svg' }}
            selectedIcon={{ uri: 'https://zos.alipayobjects.com/rmsportal/gjpzzcrPMkhfEqgbYvmN.svg' }}
            title="MORE"
            key="my"
            selected={false} >
            <div>MORE</div>
          </TabBar.Item>
        </TabBar>
      </div>

    </div>
  )

}

export default Home;
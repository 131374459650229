import React, { Component } from "react";
import { Form, Input, Button, Modal, Empty } from 'antd';
import { signupURL } from '../../api/api';
import { REST_API_URL } from "../../api/content-novareader";
import axios from "axios";

const layout = { labelCol: { span: 8 }, wrapperCol: { span: 16 } };
class NormalSiginForm extends Component {
  constructor() {
    super();
    this.state = { singUpVar: true, verfiyVar: false, loadings: false, btnText: "Signup", };
  }
  onFinish = async (values) => {
    this.setState({
      loadings: true,
      btnText: "Loading"
    })
   
    if (values.password == values.cpassword) {
      var formdata = new FormData();
      formdata.append("displayName", values.fullname);
      formdata.append("userName", values.email.toLowerCase(),);
      formdata.append("email", values.email.toLowerCase(),);
      formdata.append("password", values.password);
      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      let json = {
        firstName: values.fullname,
        lastName: "",
        email: values.email.toLowerCase(),
        password: values.password
      };
      try {
        const response = await axios.post(REST_API_URL+'/auth/signup', json, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        this.setState({
          loadings: false,
          btnText: "Signup"
        })
        this.setState({ singUpVar: false, verfiyVar: true });
        Modal.info({
          title: 'Verification',
          content: 'Thank you for registering to the TROVE web application. The link has been sent to your registered email id to verify your email account. Please follow the email to complete verification process before downloading the eBooks.',
        });
      } catch (error) {
          this.setState({
            loadings: false,
            btnText: "Signup"
          })
          Modal.error({
            title: 'Signup',
            content: error.response.data.message,
          });
      }    
    }else{
      this.setState({
        loadings: false,
        btnText: "Signup"
      })
      Modal.error({
        title: 'Mismatch',
        content: 'Password and confirm passwords are not equal.',
      });
    }
    
};
 
  onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  render() {
    const { singUpVar, verfiyVar } = this.state;
    return (
      <div>
        {singUpVar === true ?
          <Form  name="basic"
            initialValues={{ remember: true }}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
          >
            <Form.Item
              // label="Full Name"
              name="fullname"
              rules={[{ required: true, message: 'Please input your full name!' }]}
            >
              <Input placeholder="Full Name" />
            </Form.Item>
            <Form.Item
              // label="Email"
              name="email"
              rules={[{ required: true, type: 'email', message: 'Please input your email!' }]}
            >
              <Input placeholder="Email" />
            </Form.Item>

            <Form.Item
              // label="Password"
              name="password"
              type="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password  placeholder="Password"/>
            </Form.Item>
            <Form.Item
              // label="Confirm Password"
              name="cpassword"
              type="password"
              rules={[{ required: true, message: 'Please input your confirm password!' }]}
            >
              <Input.Password placeholder="Confirm Password" />
            </Form.Item>
            <Form.Item style={{ "clear": "both",marginTop:'30px' }}  >
              <Button className="loginBtn" type="primary" htmlType="submit" block loading={this.state.loadings}>
              <span className="btntext">  {this.state.btnText}</span>
              </Button>
            </Form.Item>
          </Form> : ""}
        {verfiyVar === true ?
          <Empty

            description={
              <span style={{ marginBottom: "50px" }}>
                <span><b>Verify Email</b></span>
                <br />
                Thank you for registering to the Trove Books Mobile application. The link has been sent to your registered email id to verify your email account. Please follow the email to complete verification process before downloading the eBooks.
            </span>
            }
          >
            {/* <Button type="primary">Create Now</Button> */}
          </Empty> : ""}
      </div>
    );
  }
};

export default NormalSiginForm;
const Constants={

    // accountsetting
    accountsetting:{
          header:"Account Setting",
          name:"Name",
          email:"Email",
          formheader:"Change Password",
          c_pass:"Current Password",
          n_pass:"New Password",
          cf_pass:"Confirm Password",
          btn:"Change",
    },
    //userinterests
    userinterests:{
        header:"User Interests"
    },
    //terms
    terms:{
        titel:"Terms & Conditions",
        terms1:"The articles made available through Trove Books are protected by copyright and other intellectual property laws. To the best of Newgen, the publishers that use Trove Books to make documents available have secured all necessary rights to make these documents available to be copies by the users. Use of these documents by Trove Books's users may be restricted by the user's agreement with the publisher. In addition, any user's unauthorized copying, distribution, public display, public performance, and preparation of derivative works from such documents is prohibited by copyright law.",
        terms2:"By using this service, the user represents and warrants that he or she will not use the service in any manner that would violate any third party's copyright, intellectual property, or other rights. Any violations or potential violations should promptly be reported to Email: contact@novareader.co",
        terms3:'THIS SERVICE IS PROVIDED "AS IS," WITH NO WARRANTIES WHATSOEVER. ALL EXPRESS, IMPLIED, AND STATUTORY WARRANTIES, INCLUDING, WITHOUT LIMITATION, THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT OF PROPRIETARY RIGHTS ARE EXPRESSLY DISCLAIMED TO THE FULLEST EXTENT PERMITTED BY LAW. NEITHER Trove Books NOR ITS PARENT CORPORATIONS, SUBSIDIARY CORPORATIONS, SUCCESSORS, AFFILIATES, OR ASSIGNS (COLLECTIVELY, " INGENTA ") SHALL BE LIABLE UNDER ANY CIRCUMSTANCES FOR THE USE OR MISUSE OF THIS SERVICE. SUCH LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW TO PREVENT THE RECOVERY OF DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, AND PUNITIVE DAMAGES (EVEN IF Trove Books HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES).',
        useofCookies:{
            titel:"Use of Cookies",
            header:"Trove Books uses cookies in several ways",
            p1:'Trove Books uses Google Analytics, a web analytics service provided by Google, Inc. ("Google"). Google Analytics uses "cookies", which are text files placed on your computer, to help the website analyze how users use the site. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and stored by Google. Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity for website operators and providing other services relating to website activity and internet usage. Google will not associate your IP address with any other data held by Google. You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of this website. By using this website, you consent to the processing of data about you by Google in the manner and for the purposes set out above.',
            p2:"When you visit Trove Books , you may view advertisements posted on the site by Google or other companies. These advertising companies may collect information about you while you are visiting this website and other websites. They may use this data to show you advertisements on this website and elsewhere on the Internet about products and services you might like. You may be able to opt out of such advertising. To find out more about Google, its DoubleClick DART cookie, and to opt out of its interest-based advertising, click this link: Your use of this website without opting out means that you understand and agree to data collection to provide you with interest-based advertising. Trove Books also uses this cookie to anonymously determine, what ads have been viewed, where they were located and to limit the number of times a user sees that ad."
        },
        RefundPolicy:{
            titel:"Refund Policy",
            p1:"Content available via Trove Books is available for download only. The content will be available for viewing immediately after purchase, and can continue to be accessed for the duration of the download period indicated when purchasing the item.",
            p2:"Before you complete your purchase please carefully review whether the particular product under consideration will meet your needs. Additional information on the length of the document (Page Range) is clearly flagged.",
            p3:"If you experience any problems downloading content please contact Email: contact@novareader.co and we will assist you."
        },
        Charges:{
            titel:"Deposit Account Maintenance and Audit Charges",
            p1:" An annual maintenance charge is applied to deposit accounts at October 1st each year. This is $100 for basic deposit accounts and $250 for advanced deposit accounts. Deposit accounts may be subject to an audit fee if the account has no usage for 12 months. The audit fee is 10% of the current balance or $100, whichever is greater. There will be a $100 closure fee for any deposit account that is closed resulting in a refund."

        }

    }
}

export default Constants;
import React, { useEffect, useState } from 'react';
import Error500 from '../error/500';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { home ,catalog, bookcover} from '../../api/api'
import { Row, Col,Result,Button, Image} from 'antd';
import { setSubject, setNewBooks, setBestRatingBooks, setOpenAccessBooks, setNewJournals, setBestRatingJournals } from '../../redux/actions/dashboardActions';
import ListCard from '../../component/dashboard/list_Card';
import Space from '../../component/dashboard/space';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Offline from '../error/offline';
import bannerImage from '../../asset/images/banner-sldr.png';


const Dashboards = () => {
  const history = useHistory();
  const [paidBook, setPaidBook] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const subject = useSelector((state) => state.App.subject);
  const newbooks = useSelector((state) => state.App.newbooks);
  const bestratingbooks = useSelector((state) => state.App.bestratingbooks);
  const openaccessbooks = useSelector((state) => state.App.openaccessbooks);
  const newjournals = useSelector((state) => state.App.newjournals);
  const bestratingjournals = useSelector((state) => state.App.bestratingjournals);
  const isonline = useSelector((state) => state.App.isonline);
  const emptyData = [{}, {}, {}, {}, {}]
  const [data,setdata]=useState([])
  const [error, setError] = useState(false)
  const isOnLine=localStorage.getItem('isonline')

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0)
    fetchDashboardData()
    getList()
  }, [])

 


  const fetchDashboardData = async () => {
    setIsLoading(true)
    let axios = require('axios');
    let config = {
      method: 'get',
      url: home,
      
    };
     axios(config)
    .then((response)=>{
      dispatch(setSubject(response.data.subjectList));
      dispatch(setBestRatingBooks(response.data.bestratingbooks));
      dispatch(setOpenAccessBooks(response.data.openaccessbooks));
      dispatch(setNewJournals(response.data.recentJournals));
      dispatch(setBestRatingJournals(response.data.bestRatingJournals));
      setPaidBook(response.data.bestpaidbooks);
    })
    .catch(function (error) {
      setError(true) 
      setIsLoading(false)    
    });
   }

   const getList = async () => {
    setIsLoading(true)
    let axios = require('axios');
    let config = {
      method: 'get',
      url: catalog,
      
    };
     axios(config)
    .then((response)=>{
      setdata(response.data)
      dispatch(setNewBooks(response.data.recentBooks));

      setIsLoading(false);
    })
    .catch(function (error) {
      setError(true) 
      setIsLoading(false)

    });
   }
  const goToList = (titel) => {
    history.push('/list')
  }
  const goToSubjectList = (titel) => {
  }

  if (isLoading) {
    return (
      <div>
        <Row style={{ "padding": "0px", "margin": "0px 0px 0px 0px",marginTop: '10px'}}>
          <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>

              <ListCard cardtitel=" " data={emptyData} isBook={true} access={"paid"} isLoading={true} />
              <Space hig="1px" color="#ECF0EF" />
              <ListCard cardtitel=" " data={emptyData} isBook={true} access={"paid"} isLoading={true} />
              <Space hig="1px" color="#ECF0EF" />
              <ListCard cardtitel=" " data={emptyData} isBook={true} access={"paid"} isLoading={true} />
              <Space hig="1px" color="#ECF0EF" />
              <ListCard cardtitel=" " titel="openaccessbooks" data={emptyData} isBook={true} isLoading={true} access={"free"} />
              <Space hig="1px" color="#ECF0EF" />
              <ListCard cardtitel=" " data={emptyData} isBook={true} isLoading={true} access={"free"} />
              <Space hig="1px" color="#ECF0EF" />
              <Space hig="60px" color="#fff" />


          </Col>
        </Row>

      </div>
    )
  }

  if(isOnLine=="offline"){
    return(<Offline/>)
  }
  if (error) {
    return (
      <Error500 />
    )
  }

  const goToDeacriton = (item) => {
    history.push({pathname:'/book/isbn/'+ item.identifier, state:{contentid:item.contentId, title:item.fullName, fuzzyid:item.fuzzyId} });    
  }

  return (
      <div>
        <Row>
          <Col xs="{24}">
            <Image preview={false} src={bannerImage} alt="Trove Books" />
          </Col>
        </Row>
        <Row>
        {data.recentBooks.map((item) => (
            <Col key={item.fuzzyId} xs={12} md={6} style={{ float: 'left', marginTop:'10px', padding: '10px' }}>
              <Space hig="1px" color="#ECF0EF" />
              <Image onClick={() => { goToDeacriton(item) }} className="bookcover" preview={false} src={bookcover + item.fuzzyId + "/"+item.cover}/>
              <Space hig="1px" color="#ECF0EF" />
              <p onClick={() => { goToDeacriton(item) }} style={{display: "block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}} className='ctitel'>{item.fullName}</p>
            </Col>
        ))}
        </Row>
        <Row style={{ padding: '10px', textAlign:'center', fontSize: '16px', marginBottom: '100px' }}>
          <Col xs="{24}">
            <p>"This Technical Manual and Atlas brings together two disparate but complementary themes: to provide a standardized, evidence-based framework for the performance of interventional spine procedures while also describing nuances of anatomy and image guidance that can confound the unwary or reward the thoughtful practitioner. This is experiential knowledge accrued over decades of observation and practice, often unrecorded in the literature, that is the legacy of IPSIS physicians."</p>
            <p><i>Timothy P. Maus, MD</i></p>
          </Col>
        </Row>
      </div>
  );
}

export default Dashboards;
